@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.add-new-session {
  $breakpoint: 600px;

  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__header {
    background-color: light.$background-secondary-default;
    padding: spacing.$m 0;

    @media (min-width: $breakpoint) {
      padding: spacing.$l 0;
    }
  }

  &__heading {
    margin-bottom: spacing.$m;

    @media (min-width: $breakpoint) {
      margin-bottom: spacing.$l;
    }
  }

  &__chips {
    display: flex;
    gap: spacing.$xxs;
    margin-top: spacing.$m;

    @media (min-width: $breakpoint) {
      gap: spacing.$xs;
    }
  }

  &__content {
    position: relative;
    flex-grow: 1;
    padding-bottom: 200px; // NOTE: Needed to ensure the content is always visible under the action bar
    padding-top: spacing.$m;

    @media (min-width: $breakpoint) {
      padding-top: spacing.$l;
    }
  }

  &__dark-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(13, 33, 52, 0.3);
    z-index: 3;
    display: none;

    &--active {
      display: block;
    }
  }

  &__loading-message {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: spacing.$l;
    text-align: center;
    top: spacing.$xl;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100vw - spacing.$s * 2);
    max-width: 480px;
    padding: spacing.$l spacing.$m;
    border-radius: corner-radius.$s;
    background: light.$surface-primary-default;
    z-index: 4;
  }
}
